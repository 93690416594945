<template>
  <div class="report-modal relative z-10"
       role="dialog"
       aria-labelledby="modal-title"
       aria-modal="true"
       @click="closeModal">
    <div class="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity"/>

    <div class="fixed inset-0 z-10 overflow-y-auto">
      <div class="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
        <div class="w-full max-w-xs">
          <form ref="formElement"
                class="report-modal-form shadow-md rounded px-8 pt-6 pb-8">
            <template v-if="reportIsSent">
              <div class="block text-lg font-bold mb-2">
                Report is sent
              </div>
              <div class="grid gap-4 grid-cols-1">
                <button class="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
                        type="button"
                        @click.stop="close">
                  Close
                </button>
              </div>
            </template>

            <template v-else>
              <div class="mb-4">
                <p class="block text-sm font-bold mb-2">
                  Describe the problem
                </p>
                <textarea class="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                          :class="{'border-red-500': hasError}"
                          v-model="description"
                          placeholder="Black screen or no sound"
                          @input="hasError = false"/>
                <p v-if="hasError"
                   class="text-red-500 text-xs">
                  Please fill in the field
                </p>
              </div>
              <div class="grid gap-4 grid-cols-2">
                <button class="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
                        type="button"
                        @click.stop="sendReport">
                  Send
                </button>
                <button class="mt-3 inline-flex w-full justify-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:mt-0 sm:w-auto"
                        type="button"
                        @click.stop="close">
                  Cancel
                </button>
              </div>
            </template>
          </form>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { computed, onBeforeUnmount, onMounted, ref } from 'vue'
import * as Sentry from '@sentry/vue';

const props = defineProps({
  report: Object,
})

const emit = defineEmits(['close'])

const formElement = ref(null)
const reportIsSent = ref(false)
const description = ref('')
const hasError = ref(false)

const sendReport = () => {
  if (!description.value.trim().length) {
    hasError.value = true
    return
  }

  const data = JSON.parse(JSON.stringify(props.report))
  data.description = description.value.trim()

  Sentry.withScope(scope => {
    scope.setExtras(data)
    const res = Sentry.captureMessage(`Report on user ${data.userId} quality at ${Date.now()}`)
    reportIsSent.value = true
  });
}

const closeModal = () => {
  if (formElement.value !== event.target && !formElement.value.contains(event.target)) {
    close()
  }
}

const close = () => {
  emit('close')
}
</script>

<style lang="scss">
.report-modal-form {
  background: var(--color-background-mute);
  color: var(--color-text);
}
</style>