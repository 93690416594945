<template>
  <div class="room">
    <Notifications :notifications="notifications"
                   @remove="removeNotification"/>

    <div v-show="insideRoom"
         class="room-inner">
      <div class="videos"
           :class="{'has-select' : selectedTrack}"
           :style="gridStyle">

        <MediaBlock v-if="screenStream"
                    @selectMedia="() => selectMedia(MediaStreamsKinds.Screen , userId)"
                    @sendReport="openReportModal"
                    :selected="selectedTrack?.userId === userId && selectedTrack?.streamType === MediaStreamsKinds.Screen"
                    :participant="{tracks: {[MediaStreamsKinds.Screen]: {track: screenStream}}}"
                    :isLocal="true"/>

        <MediaBlock @selectMedia="() => selectMedia(MediaStreamsKinds.Camera, userId)"
                    @sendReport="openReportModal"
                    :room="room"
                    :selected="selectedTrack?.userId === userId && selectedTrack?.streamType === MediaStreamsKinds.Camera"
                    :participant="localParticipant"
                    :source="MediaStreamsKinds.Camera"
                    :micEnabled="micEnabled"
                    :cameraEnabled="cameraEnabled"
                    :isLocal="true"/>

        <MediaBlock v-for="(user, userId) in remoteParticipants"
                    :room="room"
                    @selectMedia="() => selectMedia(MediaStreamsKinds.Camera, userId)"
                    @sendReport="openReportModal"
                    :selected="selectedTrack?.userId === userId"
                    :participant="user"
                    :is-speaking="speakersSet.has(userId)"
                    :is-muted-audio="participantsMuted || usersWithMutedAudio.has(userId)"
                    :bad-connect="badConnectionParticipants.has(userId)"
                    @setRemoteStreamQuality="setRemoteStreamQuality"
                    :key="userId"/>
      </div>

      <SettingsPanel :micEnabled="micEnabled"
                     :cameraEnabled="cameraEnabled"
                     :screenEnabled="screenEnabled"
                     :participantsMuted="participantsMuted"
                     :audioDevices="audioDevices"
                     :videoDevices="videoDevices"
                     :canChangeBitrate="canChangeBitrate"
                     :republishing="republishing"
                     :newMessage="newMessage"
                     :videoSimulcast="videoSimulcast"
                     v-model:bitrate="bitrate"
                     v-model:selectedAudioDevice="selectedAudioDevice"
                     v-model:selectedVideoDevice="selectedVideoDevice"
                     @toggleMic="toggleMic"
                     @toggleCamera="toggleCamera"
                     @toggleScreen="toggleScreen"
                     @toggleParticipantsMute="toggleParticipantsMute"
                     @leaveRoom="leaveRoom"
                     @sendRoomMessage="sendRoomMessage"
                     @toggleVideoSimulcast="toggleVideoSimulcast"
                     @update:bitrate="bitrateChanged($event)"
                     @update:selectedAudioDevice="audioDeviceChanged($event)"
                     @update:selectedVideoDevice="videoDeviceChanged($event)"
                     @broadcast="broadcast"/>
    </div>

    <div v-show="!insideRoom"
         class="room-outer">
      <button v-show="!(!cameraEnabled && (roomIsReady || connectingToRoom))"
              class="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded"
              :disabled="connectingToRoom"
              @click="enterRoom(true)">
        <FontAwesomeIcon v-show="!roomIsReady && !connectingToRoom"
                         :icon="faVideo"/>
        {{ connectToRoomWithVideoText }}
      </button>
      <button v-show="!(cameraEnabled && (roomIsReady || connectingToRoom))"
              class="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded"
              :disabled="connectingToRoom"
              @click="enterRoom(false)">
        <FontAwesomeIcon v-show="!roomIsReady && !connectingToRoom"
                         :icon="faMicrophone"/>
        {{ connectToRoomText }}
      </button>
    </div>

    <ReportModal v-if="reportData"
                 :report="reportData"
                 @close="closeReportModal"/>
  </div>
</template>

<script setup>
import adapter from 'webrtc-adapter'
import {computed, onBeforeUnmount, ref} from 'vue'
import {v4 as uuidv4} from 'uuid'
import Notifications from './components/areas/Notifications'
import MediaBlock from './components/areas/MediaBlock'
import SettingsPanel from './components/areas/SettingsPanel'
import ReportModal from './components/modals/ReportModal'
import {Call, MediaStreamsKinds} from "@/api/Call";
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import { faMicrophone, faVideo } from '@fortawesome/free-solid-svg-icons'

const canChangeBitrate = ref(true);
const republishing = ref(false);
const remoteParticipants = ref({})
const audioDevices = ref([]);
const videoDevices = ref([]);
const selectedAudioDevice = ref(null);
const selectedVideoDevice = ref(null);
const insideRoom = ref(false);
let room = null;
const connectingToRoom = ref(false);
const roomIsReady = ref(false);
const screenStream = ref(null);
const videos = ref(1);
const micEnabled = ref(true);
const cameraEnabled = ref(true);
const screenEnabled = ref(false);
const videoSimulcast = ref(true);
// const userId = uuidv4();
const userId = Date.now().toString().substr(-8);
const selectedTrack = ref(null);
const newMessage = ref(null);
const speakersSet = ref(new Set())
const usersWithMutedAudio = ref(new Set())
const defaultBitrate = 1000000;
const bitrate = ref(defaultBitrate);
const localParticipant = ref({tracks: {}});
const badConnectionParticipants = ref(new Set())
const reportData = ref(null)
const notifications = ref({})
const roomId = ref('b')
let reconnecting = false
const participantsMuted = ref(false)
const isAutoTest = ref(false)

const enterRoom = async (withVideo) => {
  if (connectingToRoom.value) {
    return;
  }

  cameraEnabled.value = withVideo;
  connectingToRoom.value = true;

  // if (isAutoTest.value) {
  //   connectServer();
  // } else {
    getDevices(() => {
      connectServer();
    });
  // }
}

const startCall = () => {
  alert('Not yet supported')
}

const sendRoomMessage = (text) => {
  if (text.trim()) {
    room.sendMessage(text)
  }
}

const resetCameraStream = async () => {
  if (localParticipant.value.tracks[MediaStreamsKinds.Camera]) {
    delete localParticipant.value.tracks[MediaStreamsKinds.Camera];
  }
}

const resetScreenStream = () => {
  if (screenStream.value) {
    screenStream.value.stop()
    screenStream.value = null;
  }
}
const resetApp = () => {
  reconnecting = false;
  canChangeBitrate.value = true;
  connectingToRoom.value = false;
  insideRoom.value = false;
  cameraEnabled.value = true;
  micEnabled.value = isAutoTest.value ? false : true;
  audioDevices.value = [];
  videoDevices.value = [];
  republishing.value = false;
  videoSimulcast.value = urlParams.get('disableSimulcast') !== 'true';

  for (let key in remoteParticipants.value) {
    delete remoteParticipants.value[key];
  }

  videos.value = 1;

  resetCameraStream()

  if (screenStream.value) {
    resetScreenStream()
    screenEnabled.value = false;
  }
  roomIsReady.value = false;
}

const leaveRoom = () => {
  resetApp()

  if (room) {
    room.hangup();
    room = null;
  }
}

const audioDeviceChanged = async (device) => {
  selectedAudioDevice.value = device;
  localStorage.setItem('savedAudioDeviceId', device.deviceId)

  await room.switchActiveAudioDevice(selectedAudioDevice.value.deviceId);
}

const videoDeviceChanged = async (device) => {
  selectedVideoDevice.value = device;
  localStorage.setItem('savedVideoDeviceId', device.deviceId)

  await room.switchActiveVideoDevice(selectedVideoDevice.value.deviceId);
  if (cameraEnabled.value) {
    const videoTrack = await room.getLocalVideo();
    localParticipant.value.tracks[MediaStreamsKinds.Camera].track = videoTrack
  }
}

const broadcast = (roomId) => {
  if (room) {
    room.startRelay(roomId)
  }
}

const bitrateChanged = async (newBitrate) => {
  canChangeBitrate.value = false;
  const parsedBitrate = parseInt(newBitrate);
  await room.setBitrate(parsedBitrate);
  await room.changeStreamQuality({videoBitrate: parsedBitrate});
  canChangeBitrate.value = true;
}

const setSelectedTrackData = (userId, streamType) => {
  selectedTrack.value = {userId, streamType};
}

const setRemoteStreamQuality = (userId, quality) => {
  const participant = room.getParticipants()[userId]
  participant.setStreamQuality(quality)
}

const addNotification = (text, permanent) => {
  const notificationId = Object.keys(notifications.value).length + 1
  notifications.value[notificationId] = {
    id: notificationId,
    text: text,
    timeout: permanent ? 0 : 5000,
  }
}

const removeNotification = (id) => {
  delete notifications.value[id]
}

const handleConnected = async () => {
  console.log('handleConnected')
  connectingToRoom.value = false
  roomIsReady.value = true
  insideRoom.value = true

  if (cameraEnabled.value && micEnabled.value) {
    room.startStream()
  } else if (cameraEnabled.value) {
    room.enableVideo()
  } else if (micEnabled.value) {
    room.enableAudio()
  }
}

const handlePublishSucceed = async (MediaStreamKind) => {
  console.log('PublishSucceed', MediaStreamKind)
  if (MediaStreamKind === MediaStreamsKinds.Camera) {
    const videoTrack = await room.getLocalVideo()
    if (!localParticipant.value.tracks?.[MediaStreamKind]) {
      localParticipant.value.tracks[MediaStreamKind] = {}
    }
    localParticipant.value.tracks[MediaStreamKind].track = videoTrack
    localParticipant.value.tracks[MediaStreamKind].source = MediaStreamKind
  } else if (MediaStreamKind === MediaStreamsKinds.Screen) {
    const streamTrack = await room.getLocalScreen()
    screenStream.value = streamTrack
    videos.value++;
    setSelectedTrackData(userId, MediaStreamsKinds.Screen);
  }
  republishing.value = false;
}

const handleRemoteMediaAdded = (Participant, Track) => {
  console.log('RemoteMediaAdded', Participant, Track)
  try {
    remoteParticipants.value[Participant.userId] = {...Participant};
  } catch (e) {
    console.error(e, Participant, Track)
  }
};

const handleRemoteMediaRemoved = (Participant, Track) => {
  console.log('RemoteMediaRemoved', Participant, Track);
  try {
    if (insideRoom.value) {
      const userId = Participant.userId;
      remoteParticipants.value[Participant.userId] = {...Participant};
      if (selectedTrack.value && selectedTrack.value?.userId === userId) {
        selectedTrack.value = null;
        room.resetMainStream();
      }

      if (Track.source === MediaStreamsKinds.Microphone) {
        usersWithMutedAudio.value.delete(Participant.userId);
      }
    }
  } catch (e) {
    console.error(e, Participant, Track)
  }
};

const handleRemoteMediaMuted = (Participant, Track) => {
  console.log('handleRemoteMediaMuted', Participant, Track);
  usersWithMutedAudio.value.add(Participant.userId);
};

const handleRemoteMediaUnmuted = (Participant, Track) => {
  console.log('handleRemoteMediaUnmuted', Participant, Track);
  usersWithMutedAudio.value.delete(Participant.userId);
};

const handleParticipantJoined = (Participant) => {
  console.log('ParticipantJoined', Participant)
  if (Participant.isMutedAudio) {
    usersWithMutedAudio.value.add(Participant.userId);
  }
  remoteParticipants.value[Participant.userId] = {...Participant};
  videos.value++
}

const handleParticipantStateUpdated = (Participant) => {
  console.log('ParticipantStateUpdated', Participant)
  remoteParticipants.value[Participant.userId] = {...Participant};
}

const handleParticipantLeaved = (Participant) => {
  console.log('handleParticipantLeaved', Participant)
  if (remoteParticipants.value[Participant.userId]) {
    videos.value--
    delete remoteParticipants.value[Participant.userId];
    if (usersWithMutedAudio.value.has(Participant.userId)) {
      usersWithMutedAudio.value.delete(Participant.userId);
    }
  }
}

const handleMessageReceived = (message) => {
  console.log('handleMessageReceived', message)
  newMessage.value = message
}

const handleVoiceStarted = (Participant) => {
  speakersSet.value.add(Participant.userId)
  try {
    if ('isSpeaking' in remoteParticipants.value[Participant.userId]) {
      remoteParticipants.value[Participant.userId].isSpeaking = true;
    }
  } catch (e) {
    console.error(e, Participant)
  }
}

const handleVoiceEnded = (Participant) => {
  speakersSet.value.delete(Participant.userId)
  try {
    if ('isSpeaking' in remoteParticipants.value[Participant.userId]) {
      remoteParticipants.value[Participant.userId].isSpeaking = false;
    }
  } catch (e) {
    console.error(e, Participant)
  }
}

const handlePublishEnded = (MediaStreamKind) => {
  console.log('handlePublishEnded', MediaStreamKind)
  switch (MediaStreamKind) {
    case MediaStreamsKinds.Camera:
      addNotification('Your video stream is stopped. Possible reason is a device is disconnected or a program is stopped.\nCheck your settings and try re-enable a camera.')
      toggleCamera()
      break
    case MediaStreamsKinds.Microphone:
      addNotification('Your audio stream is stopped. Possible reason is a device is disconnected or a program is stopped.\nCheck your settings and try re-enable a microphone.')
      micEnabled.value = false
      room.disableAudio()
      break
    case MediaStreamsKinds.Screen:
      toggleScreen()
      break
  }
}

const handleDisconnected = () => {
  console.log('handleDisconnected')
  if (insideRoom.value) {
    addNotification('Connection is lost. Possible reason is network issue.\nTry to connect again.')
    leaveRoom()
  }
}

const handlePublishFailed = (MediaStreamKind) => {
  console.log('PublishFailed', MediaStreamKind)
  switch (MediaStreamKind) {
    case MediaStreamsKinds.Camera:
      addNotification('Can\'t publish video stream. Check your device or permissions and try again.')
      toggleCamera()
      break
    case MediaStreamsKinds.Microphone:
      addNotification('Can\'t publish audio stream. Check your device or permissions and try again.')
      micEnabled.value = false
      room.disableAudio()
      break
    case MediaStreamsKinds.Screen:
      addNotification('Can\'t publish screen share stream. Check your device or permissions and try again.')
      toggleScreen()
      break
  }
}

const handleReconnecting = () => {
  console.log('handleReconnecting')
  if (!reconnecting) {
    reconnecting = true
    addNotification('Reconnecting...', true)
  }
}

const handleReconnected = () => {
  console.log('handleReconnected')
  reconnecting = false
  addNotification('Reconnected')

  if (cameraEnabled.value && micEnabled.value) {
    room.startStream()
  } else if (cameraEnabled.value) {
    room.enableVideo()
  } else if (micEnabled.value) {
    room.enableAudio()
  }
}

const handleFailed = () => {
  console.log('handleFailed')
  leaveRoom()
}

const handleCallStatsReceived = (Stats) => {
  // console.log('handleCallStatsReceived', Stats)
}
const handleUpdatePacketLoss = (Participants) => {
  console.log('handleUpdatePacketLoss', Participants)

  const prevParticipantsWithBadConnection = new Set([...badConnectionParticipants.value.values()]);
  badConnectionParticipants.value.clear()

  Participants.forEach(userId => {
    const participant = remoteParticipants.value?.[userId]
    if (participant) {
      badConnectionParticipants.value.add(userId)
      prevParticipantsWithBadConnection.delete(userId)
    }
  })
  const participantsWithGoodConnection = [...prevParticipantsWithBadConnection.values()]
  participantsWithGoodConnection.forEach((userId) => {
    const participant = remoteParticipants.value?.[userId]
    if (participant) {
    }
  })
}

const connectServer = async () => {
  room = new Call();

  room.on('Connected', handleConnected)
  room.on('PublishSucceed', handlePublishSucceed)
  room.on('RemoteMediaAdded', handleRemoteMediaAdded)
  room.on('RemoteMediaRemoved', handleRemoteMediaRemoved)
  room.on('RemoteMediaMuted', handleRemoteMediaMuted)
  room.on('RemoteMediaUnmuted', handleRemoteMediaUnmuted)
  room.on('ParticipantJoined', handleParticipantJoined)
  room.on('ParticipantStateUpdated', handleParticipantStateUpdated)
  room.on('ParticipantLeaved', handleParticipantLeaved)
  room.on('MessageReceived', handleMessageReceived)
  room.on('VoiceStarted', handleVoiceStarted)
  room.on('VoiceEnded', handleVoiceEnded)
  room.on('PublishEnded', handlePublishEnded)
  room.on('Reconnecting', handleReconnecting)
  room.on('Reconnected', handleReconnected)
  room.on('Disconnected', handleDisconnected)
  room.on('Failed', handleFailed)
  room.on('CallStatsReceived', handleCallStatsReceived)
  room.on('PublishFailed', handlePublishFailed)
  room.on('UpdatePacketLoss', handleUpdatePacketLoss)

  await room.connect({
    roomId: roomId.value,
    userId: userId,
    videoBitrate: bitrate.value,
    videoSimulcast: videoSimulcast.value,
    audioDeviceId: selectedAudioDevice.value?.deviceId,
    videoDeviceId: selectedVideoDevice.value?.deviceId,
  })
};

const toggleMic = () => {
  micEnabled.value = !micEnabled.value;
  if (micEnabled.value) {
    room.enableAudio();
  } else {
    room.disableAudio();
  }
}

const toggleCamera = () => {
  cameraEnabled.value = !cameraEnabled.value;
  if (cameraEnabled.value) {
    room.enableVideo();
  } else {
    resetCameraStream();
    room.disableVideo();
  }
}

const toggleScreen = () => {
  screenEnabled.value = !screenEnabled.value;
    if (screenEnabled.value) {
      room.startScreenShare();
    } else {
      if (screenStream.value) {
        videos.value--;
        room.stopScreenShare();
        resetScreenStream();
      }
    }
}

const toggleVideoSimulcast = async () => {
  console.log('toggleVideoSimulcast')
  republishing.value = true;
  videoSimulcast.value = !videoSimulcast.value
  await room.changeStreamQuality({videoSimulcast: videoSimulcast.value})
}

const toggleParticipantsMute = () => {
  console.log('toggleParticipantsMute')
  participantsMuted.value = !participantsMuted.value;
}

const connectToRoomWithVideoText = computed(() => {
  if (roomIsReady.value) {
    return 'Connected';
  } else if (connectingToRoom.value) {
    return 'Connecting';
  }

  return 'Join with video';
});

const connectToRoomText = computed(() => {
  if (roomIsReady.value) {
    return 'Connected';
  } else if (connectingToRoom.value) {
    return 'Connecting';
  }

  return 'Join without video';
});

const gridStyle = computed(() => {
    const rows = videos.value ? Math.round(Math.sqrt(videos.value)) : 1;
    const cols = videos.value ? Math.ceil(videos.value / rows) : 1;

    return {
      'grid-template-columns': `repeat(${cols}, 1fr)`,
      'grid-template-rows': `repeat(${rows}, 1fr)`,
    }
})

const openReportModal = (report) => {
  const reporter = room.getLocalUserId();
  if (!report.userId) {
    report.userId = reporter;
    report.reporter = 'self';
    report.video = micEnabled.value;
    report.audio = cameraEnabled.value;
    report.screen = !!screenStream.value
  } else {
    report.reporter = reporter;
  }

  reportData.value = report;
}

const closeReportModal = () => {
  reportData.value = null;
}

const getDevices = async (onSuccess) => {
  let media = null
  try {
    media = await navigator.mediaDevices.getUserMedia({audio: true, video: true});

    const savedAudioDeviceId = localStorage.getItem('savedAudioDeviceId')
    const savedVideoDeviceId = localStorage.getItem('savedVideoDeviceId')

    const devices = await navigator.mediaDevices.enumerateDevices();
    devices.forEach(d => {
      if (d.kind === 'audioinput') {
        const device = {
          deviceId: d.deviceId,
          groupId: d.groupId,
          kind: d.kind,
          label: d.label,
        }
        audioDevices.value.push(device);

        if (d.deviceId === savedAudioDeviceId) {
          selectedAudioDevice.value = device;
        }
      } else if (d.kind === 'videoinput') {
        const device = {
          deviceId: d.deviceId,
          groupId: d.groupId,
          kind: d.kind,
          label: d.label,
        }
        videoDevices.value.push(device);

        if (d.deviceId === savedVideoDeviceId) {
          selectedVideoDevice.value = device;
        }
      }
    })

    if (!selectedAudioDevice.value && audioDevices.value.length) {
      selectedAudioDevice.value = audioDevices.value[0];
    }

    if (!selectedVideoDevice.value && videoDevices.value.length) {
      selectedVideoDevice.value = videoDevices.value[0];
    }

    await Promise.all(devices)
    onSuccess();
  } catch (error) {
    if (isAutoTest.value) {
      // for bots we can ignore media device related errors
      onSuccess();
    } else {
      leaveRoom();
      addNotification(`To make a call, you need to have both camera and microphone connected to your PC / Mac and grant permissions for them <b>(even for calls without video)</b>`);
      console.error(`${error.name}: ${error.message}`)
    }
  } finally {
    media?.getTracks()?.forEach(track => track.stop())
  }
}

const selectMedia = (trackType, user) => {
  if (selectedTrack.value) {
    selectedTrack.value = null
    if (user !== userId) {
      room.resetMainStream();
    }
  } else {
    if (user !== userId) {
      const kind = remoteParticipants.value[user]?.screenSharingEnabled
        ? MediaStreamsKinds.Screen
        : MediaStreamsKinds.Camera;
      room.setMainStream(user, kind);
    }
    setSelectedTrackData(user, trackType);
  }
}

const urlParams = new URLSearchParams(document.location.search.substring(1));
const roomFromParams = urlParams.get('roomId');
const createFromParams = urlParams.get('create');
if (roomFromParams) {
  roomId.value = roomFromParams;
}

isAutoTest.value = !!urlParams.get('auto');
videoSimulcast.value = urlParams.get('disableSimulcast') !== 'true';
bitrate.value = parseInt(urlParams.get('maxBitrate')) || defaultBitrate;

if (isAutoTest.value) {
  micEnabled.value = false;
  enterRoom(true);
}

onBeforeUnmount(() => {
  leaveRoom();
});
</script>

<style lang="scss">
#app {
  height: 100vh;
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;

  .room {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100%;

    &-outer {
      display: flex;
      flex-direction: column;
      gap: 2rem;
    }

    &-inner {
      display: flex;
      flex-direction: column;
      gap: 2rem;
      width: 100%;
      height: 100%;
    }
  }

  .videos {
    display: grid;
    gap: 10px;
    flex: 1;
    min-height: 0;
    position: relative;
    cursor: pointer;

    &.has-select {

      & .selected {
        //order: -2;
        //grid-column: 1 / -1;
        z-index: 3;
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background: #000000;
      }
    }
  }
}
</style>
