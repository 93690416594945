<template>
  <div class="video-block"
       :class="[{'my': isLocal}, {'selected': selected}, {'speaker': isSpeaking && !isMuteAudioBtn} ]">

    <div class="video-block-actions video-block-actions--top"
         :class="{'video-block-actions--my': isLocal}">
      <div class="video-block-settings icon bg-blue-500"
           v-if="!isLocal"
           :class="{'bg-neutral-500' : isShowSettings}"
           @click="toggleSettingsShow">
        <FontAwesomeIcon :class="{active : !isMuteVideoBtn}"
                         :icon="faBars"/>
      </div>

      <div class="video-block-settings icon bg-neutral-500"
           title="Send report on user quality"
           @click="sendReport">
        <FontAwesomeIcon :icon="faBullhorn" />
      </div>
    </div>

    <div class="video-block-state">
      <div class="video-block-state-signal icon" v-if="badConnect">
        <FontAwesomeIcon :icon="faSignal"/>
      </div>

      <div class="video-block-state-mic icon" v-if="isMuteAudio">
        <FontAwesomeIcon :icon="faMicrophoneLinesSlash"/>
      </div>
    </div>

    <FontAwesomeIcon v-if="isMuteVideo && !screenShareStream"
                     class="user-icon"
                     :icon="faUser"/>
    
    <div class="video-block-info-bitrate">
      Bitrate: {{videoBitrate}}
    </div>

    <video v-if="!isMuteVideo"
           @click="$emit('selectMedia')"
           class="video-element"
           :class="[{camera : props.source === MediaStreamsKinds.Camera}, {'with-screen' : screenShareStream}]"
           :srcObject.prop="videoStream"
           playsinline
           muted
           autoplay/>

    <video v-if="screenShareStream"
           @click="$emit('selectMedia')"
           class="video-element"
           :class="{camera : props.source === MediaStreamsKinds.Camera}"
           :srcObject.prop="screenShareStream"
           playsinline
           muted
           autoplay/>

    <audio v-if="!isMuteAudio"
           :srcObject.prop="audioStream"
           autoplay/>

    <div class="video-block-info" v-if="isShowSettings">
      <span v-if="!isLocal">
        Participant {{participantComp.userId}}
      </span>
      <div v-if="!isLocal && !screenTrack && !isMuteVideoBtn">
        <div class="video-block-info-actions">
          <VueMultiselect v-model="selectedQuality"
                          v-if="videoTrack?.layers"
                          :options="qualities"
                          :allowEmpty="false"
                          label="label"
                          track-by="label"
                          @select="updateQuality"/>
        </div>
        <div class="video-block-info-packets">
          PacketsLost: {{packetLoss}}
        </div>
        <div class="video-block-info-resolutions">
          Resolution: {{videoResolution}}
        </div>
      </div>
    </div>

    <!-- temporarily hid elements for local mute tracks of remote participants -->
<!--     <div class="video-block-actions video-block-actions--bottom" v-if="!isLocal">
      <div class="video-block-actions-video icon bg-blue-500"
           :class="{'bg-neutral-500' : isMuteVideoBtn}"
           v-if="videoTrack">
        <FontAwesomeIcon @click="toggleCamera"
                         :icon="faVideoCamera"/>
      </div>

      <div class="video-block-actions-audio icon bg-blue-500"
           :class="{'bg-neutral-500' : isMuteAudioBtn}"
           v-if="audioTrack">
        <FontAwesomeIcon @click="toggleMic"
                         :icon="faMicrophone"/>
      </div>
    </div> -->
  </div>
</template>

<script setup>
import {computed, onBeforeUnmount, onMounted, ref} from 'vue'
import {FontAwesomeIcon} from '@fortawesome/vue-fontawesome'
import {
  faBars,
  faCamera,
  faMicrophone,
  faMicrophoneLinesSlash,
  faSignal,
  faUser,
  faVideoCamera,
  faBullhorn
} from '@fortawesome/free-solid-svg-icons'
import VueMultiselect from 'vue-multiselect'
import {MediaStreamsKinds} from "@/api/Call";

const props = defineProps({
  tracks: Object,
  track: Object,
  isLocal: Boolean,
  source: Number,
  selected: Boolean,
  room: Object,
  participant: Object,
  isSpeaking: Boolean,
  isMutedAudio: Boolean,
  badConnect: Boolean,
  micEnabled: {
    type: Boolean,
    default: true,
  },
  cameraEnabled: {
    type: Boolean,
    default: true,
  }
});

const emit = defineEmits(['setRemoteStreamQuality', 'selectMedia', 'sendReport'])
const packetLoss = ref(0)
const selectedQuality = ref(null);
const isShowSettings = ref(null);

let videoStats = {};
let audioStats = {};
let screenStats = {};

const qualities = ref([
  {
    id: 2,
    label: 'HIGH QUALITY',
  },
  {
    id: 1,
    label: 'MEDIUM QUALITY',
  },
  {
    id: 0,
    label: 'LOW QUALITY',
  },
])
const updateQuality = (quality) => {
  emit('setRemoteStreamQuality',participantComp.value.userId, quality.id)
}

const videoEnabled = ref(props.isLocal)
const audioEnabled = ref(props.isLocal)
const videoBitrate = ref('-')
const videoResolution = ref(null)
const trackSettingsUpdateInterval = ref(null)
const isMuteVideoBtn = ref(false);
const isMuteAudioBtn = ref(false);

const videoTrack = computed(() => {
    return participantComp.value?.tracks?.[MediaStreamsKinds.Camera]?.track
})
const audioTrack = computed(() => {
  return participantComp.value.tracks?.[MediaStreamsKinds.Microphone]?.track
})
const screenTrack = computed(() => {
    return participantComp.value?.tracks?.[MediaStreamsKinds.Screen]?.track
})

const participantComp = computed(() => {
  return props.participant
})

const videoStream = computed(() => {
  if (videoTrack.value) {
    return new MediaStream([videoTrack.value])
  }
})

const screenShareStream = computed(() => {
    if (screenTrack.value) {
      return new MediaStream([screenTrack.value])
    }
})

const audioStream = computed(() => {
  if (audioTrack.value) {
    return new MediaStream([audioTrack.value])
  }
})

const toggleSettingsShow = () => {
  isShowSettings.value = !isShowSettings.value
}

const isMuteVideo = computed(() => {
  return (!videoTrack.value && !props.isLocal) || (props.isLocal && !props.cameraEnabled) || isMuteVideoBtn.value;
})
const isMuteAudio = computed(() => {
  return props.isLocal
    ? !props.micEnabled
    : props.isMutedAudio || !audioEnabled.value;
})

const processReport = (report, prevStats) => {
  const stats = {};
  const {packetsLost, timestamp, bytesReceived} = report
  const _packetLost = packetsLost - (prevStats?.packetsLost || 0);
  stats.packetLoss = _packetLost < 0 ? 0 : _packetLost;

  const bytes = (bytesReceived - (prevStats.bytesReceived || 0));
  const time = timestamp - (prevStats.timestamp || 0);
  const bitrate = 8 * bytes / (time / 1000);
  stats.bitrate = bitrate < 0 ? 0 : Math.trunc(bitrate)
  stats.packetsLost = packetsLost
  stats.bytesReceived = bytesReceived
  stats.timestamp = timestamp

  stats.framesDropped = 'framesDropped' in report ? report.framesDropped : '-'
  stats.freezeCount = 'freezeCount' in report ? report.freezeCount : '-'
  stats.pliCount = 'pliCount' in report ? report.pliCount : '-'
  stats.nackCount = 'nackCount' in report ? report.nackCount : '-'

  return stats;
}

const prevReports = {};
const indexes = {'q': 0, 'h': 1, 'f': 2};

const getStats = async (track, callback) => {
  try {
    const stats = await props.room?.recipient?.getStats?.(track)
    for (const report of stats.values()) {
      if (report.type === 'inbound-rtp') {
        callback(report)
        break
      }
    }
  } catch (e) {
    // if we're here it's almost okay
    // we tried to get stats, but the user has already left
    // so there is nothing to report and worry about
  }
}

const getLocalStats = async() => {
  const stats = await props.room?.sender?.getStats?.();
  const bitrates = [];
  for (const report of stats.values()) {
    if (report.type === 'outbound-rtp' && report.kind === 'video' && report.contentType !== 'screenshare') {
      const prevReport = prevReports[report.id] || {};
      const bytes = report.bytesSent - (prevReport.bytesSent || 0);
      const time = report.timestamp - (prevReport.timestamp || 0);
      const bitrate = 8 * bytes / (time / 1000);
      const index = indexes[report.rid] || 0;
      bitrates[index] = bitrate < 0 ? 0 : Math.trunc(bitrate);
      prevReports[report.id] = report;
    }
  }

  if (bitrates.length === 3) {
    videoBitrate.value = `${bitrates[0]} | ${bitrates[1]} | ${bitrates[2]}`;
  } else {
    videoBitrate.value = bitrates[0];
  }
}

onMounted(() => {
  if (!props.isLocal) {
    trackSettingsUpdateInterval.value = setInterval(async () => {

      if (videoTrack.value) {
        selectedQuality.value = qualities.value.find(q => q.id === videoTrack.value.currentVideoQuality);

        const settings = videoTrack.value.getSettings()
        if (settings) {
          videoResolution.value = `${settings?.width}x${settings?.height}`
        }

        getStats(videoTrack.value, (report) => {
          videoStats = processReport(report, videoStats)
          packetLoss.value = videoStats.packetsLost
          videoBitrate.value = videoStats.bitrate
        })
      }

      if (audioTrack.value) {
        getStats(audioTrack.value, (report) => {
          audioStats = processReport(report, audioStats)
        })
      }

      if (screenTrack.value) {
        getStats(screenTrack.value, (report) => {
          screenStats = processReport(report, screenStats)
        })
      }

      videoEnabled.value = (participantComp.value.videoEnabled || screenTrack.value) && !participantComp.value.isMutedVideo;
      audioEnabled.value = audioTrack.value;
    }, 1000)
  } else {
    trackSettingsUpdateInterval.value = setInterval(async () => {
      if (videoTrack.value) {
        getLocalStats();
      } else {
        videoBitrate.value = '-';
      }
    }, 1000);
  } 
})

const toggleCamera = () => {
  isMuteVideoBtn.value = !isMuteVideoBtn.value
  if (!isMuteVideoBtn.value) {
    participantComp.value.enableVideo();
  } else {
    participantComp.value.disableVideo();
  }
}

const toggleMic = () => {
  isMuteAudioBtn.value = !isMuteAudioBtn.value
  if (!isMuteAudioBtn.value) {
    participantComp.value.enableAudio();
  } else {
    participantComp.value.disableAudio();
  }
}

const sendReport = () => {
  const data = {
    userId: participantComp.value.userId,
    video: false,
    audio: false,
    screen: false,
  }

  if (videoTrack.value) {
    data['video'] = {
      packetsLost: videoStats.packetsLost,
      bitrate: videoStats.bitrate,
      framesDropped: videoStats.framesDropped,
      freezeCount: videoStats.freezeCount,
      pliCount: videoStats.pliCount,
      nackCount: videoStats.nackCount,
      quality: selectedQuality.value?.label,
    }
  }

  if (audioTrack.value) {
    data['audio'] = {
      packetsLost: audioStats.packetsLost,
      bitrate: audioStats.bitrate,
      nackCount: audioStats.nackCount,
    }
  }

  if (screenTrack.value) {
    data['screen'] = {
      packetsLost: screenStats.packetsLost,
      bitrate: screenStats.bitrate,
      framesDropped: screenStats.framesDropped,
      freezeCount: screenStats.freezeCount,
      pliCount: screenStats.pliCount,
      nackCount: screenStats.nackCount,
    }
  }

  emit('sendReport', data)
}

onBeforeUnmount(() => {
  clearInterval(trackSettingsUpdateInterval.value)
});
</script>

<style lang="scss">
.video-block {
  max-width: 100%;
  max-height: 100%;
  position: relative;
  overflow: hidden;
  border: 3px solid transparent;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  outline: 1px solid grey;

  & .icon {
    color: white;
    padding: 0.5rem;
    border-radius: 50%;
    width: 2rem;
    height: 2rem;
    z-index: 2;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  &-state {
    position: absolute;
    top: 0.2rem;
    right: 0.2rem;
    display: flex;
    gap: 1rem;

    &-signal {
      background: red;
    }

    &-mic {
      background: grey;
    }
  }

  &-info {
    text-align: right;
    padding: 2.5rem 0 0.5rem;
    background: rgba(0, 0, 0, 0.4);
    color: #FFFFFF;
    position: relative;
    z-index: 1;

    &-resolutions {
      display: none;
    }

    &:hover &-resolutions {
      display: block;
    }

    &-bitrate {
      position: absolute;
      top: 0;
      right: 0;
      z-index: 1;
      padding: 5px 10px;
      color: #fff;
      background: rgb(0 0 0 / 50%);
      border-radius: 10px;
    }
  }

  &-actions {
    position: absolute;
    z-index: 2;
    left: 0;
    display: flex;
    gap: 1rem;
    padding: 0.2rem;

    &--my {
      left: 2.5rem;
    }

    &--top {
      top: 0;
    }

    &--bottom {
      bottom: 0;
    }
  }

  .video-element {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);

    &.with-screen {
      width: 6rem;
      height: 6rem;
      z-index: 2;
      object-fit: cover;
      border-radius: 50%;
      transform: unset;
      top: unset;
      left: unset;
      bottom: 1rem;
      right: 1rem;
    }
  }

  &.my {
    order: -1;
    position: relative;
    border: 1px solid #009688;

    &:after {
      content: 'me';
      padding: 5px;
      position: absolute;
      top: 0;
      left: 0;
      background: #009688;
      color: #FFF;
    }

    & .video-element {
      &.camera {
        transform: translate(-50%, -50%) scaleX(-1);
      }
    }
  }

  &.speaker {
    border: 3px solid #5188fd;
  }

  & .user-icon {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
  }
}
</style>
