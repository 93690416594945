<template>
  <div class="notifications">
    <Notification v-for="notification in notificationSlice"
                  :text="notification.text"
                  :timeout="notification.timeout"
                  :key="notification.id"
                  @remove="remove(notification.id)"/>
  </div>
</template>

<script setup>
import {computed} from 'vue'
import Notification from './Notification'

const props = defineProps({
  notifications: Object
})

const emit = defineEmits(['remove'])

const notificationSlice = computed(() => {
  return Object.values(props.notifications).slice(-3).reverse()
})

const remove = (id) => {
  emit('remove', id)
}
</script>

<style lang="scss">
.notifications {
  width: 300px;
  max-width: calc(100% - 20px);
  position: absolute;
  top: 10px;
  right: 10px;
  z-index: 10;
}
</style>