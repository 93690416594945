<template>
  <div class="flex items-center p-4 mb-4 text-yellow-800 rounded-lg bg-yellow-50 dark:bg-gray-800 dark:text-yellow-300">
    <div class="text-sm font-medium"
         v-html="text"/>
    <button class="ml-auto -mx-1.5 -my-1.5 bg-yellow-50 text-yellow-500 rounded-lg focus:ring-2 focus:ring-yellow-400 p-1.5 hover:bg-yellow-200 inline-flex items-center justify-center h-8 w-8 dark:bg-gray-800 dark:text-yellow-300 dark:hover:bg-gray-700"
            @click="remove">
      <svg class="w-3 h-3"
           xmlns="http://www.w3.org/2000/svg"
           fill="none"
           viewBox="0 0 14 14">
        <path stroke="currentColor"
              stroke-linecap="round"
              stroke-linejoin="round"
              stroke-width="2"
              d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6"/>
      </svg>
    </button>
  </div>
</template>

<script setup>
import {onBeforeUnmount} from 'vue'

const props = defineProps({
  text: String,
  timeout: Number
})

const emit = defineEmits(['remove'])

let timeout

const remove = () => {
  emit('remove')
}

if (props.timeout) {
  timeout = setTimeout(() => {
    emit('remove')
  }, props.timeout)
}

onBeforeUnmount(() => {
  clearTimeout(timeout)
})
</script>