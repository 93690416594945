<template>
  <div class="tools">
    <!-- messages are temporarily hidden -->
<!--     <div class="tools-element message">
      <form action="" @submit.prevent="submitHandler">
        <input :class="inputClasses"
               placeholder="Введите сообщение"
               type="text"
               name="message"
               :disabled="republishing || !canChangeBitrate"
        />
        <button :class="buttonClasses">
          Send
        </button>
      </form>
      <input :class="inputClasses"
             :value="props.newMessage?.text || 'В чате нет сообщений' "
             type="text"
             name="message"
             disabled
             :disabled="republishing || !canChangeBitrate"
      />
    </div> -->

    <div v-if="canChangeDevices"
         class="tools-element">
      <VueMultiselect :modelValue="selectedVideoDevice"
                      :options="videoDevices"
                      :allowEmpty="false"
                      :disabled="republishing"
                      label="label"
                      track-by="label"
                      @select="emit('update:selected-video-device', $event)"/>
    </div>

    <div v-if="canChangeDevices"
         class="tools-element">
      <VueMultiselect :modelValue="selectedAudioDevice"
                      :options="audioDevices"
                      :allowEmpty="false"
                      :disabled="republishing"
                      label="label"
                      track-by="label"
                      @select="emit('update:selected-audio-device', $event)"/>
    </div>

    <!-- bitrate is temporarily hidden -->
<!--     <div class="tools-element">
      <input :class="inputClasses"
             type="number"
             name="bitrate"
             :value="bitrate"
             :disabled="republishing || !canChangeBitrate"
             @change="emit('update:bitrate', $event.target.value)"/>
    </div> -->

<!--     <div class="tools-element">
      <VueMultiselect :modelValue="quality"
                      :options="qualityOptions"
                      :allowEmpty="false"
                      label="label"
                      track-by="label"
                      @select="emit('update:selectedAudioDevice', $event)"/>
    </div> -->


    <div class="tools-element buttons"
         :class="{'without-devices': !canChangeDevices}">
      <button :class="buttonClasses"
              @click="toggleMic">
        <FontAwesomeIcon :icon="micEnabled ? faMicrophone : faMicrophoneSlash" /><br/>
        {{ micText }}
      </button>

      <button :class="buttonClasses"
              @click="toggleCamera">
        <FontAwesomeIcon :icon="cameraEnabled ? faVideo : faStopCircle" /><br/>
        {{ cameraText }}
      </button>

      <button :class="buttonClasses"
              @click="toggleScreen">
        <FontAwesomeIcon :icon="faDesktop" /><br/>
        {{ screenText }}
      </button>

      <button :class="buttonClasses"
              @click="toggleParticipantsMute">
        <FontAwesomeIcon :icon="participantsMuted ? faVolumeHigh : faVolumeXmark" /><br/>
        {{ muteAllText }}
      </button>

      <button :class="buttonClasses"
              @click="leaveRoom">
        <FontAwesomeIcon :icon="faRightFromBracket" /><br/>
        Leave
      </button>
    </div>

    <div class="tools-element">
      <input :class="inputClasses"
             name="broadcast"
             v-model="roomId"
             placeholder="room to broadcast"/>
      <button :class="buttonClasses"
              @click="broadcast">Broadcast</button>
    </div>

    <!-- simulcast is temporarily hidden -->
<!--     <div class="tools-element buttons">
      <button :class="buttonClasses"
              :disabled="republishing"
              @click="toggleVideoSimulcast">
        Simulcast: {{ props?.videoSimulcast ? 'on' : 'off'}}
      </button>
    </div> -->
  </div>
</template>

<script setup>
import {computed, onBeforeMount, reactive, ref, toRef, toRefs} from 'vue'
import VueMultiselect from 'vue-multiselect'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import {
  faRightFromBracket,
  faMicrophone,
  faVideo,
  faDesktop,
  faMicrophoneSlash,
  faStopCircle,
  faVolumeHigh,
  faVolumeXmark
} from '@fortawesome/free-solid-svg-icons'

const props = defineProps({
  width: Number,
  height: Number,
  micEnabled: Boolean,
  cameraEnabled: Boolean,
  screenEnabled: Boolean,
  participantsMuted: Boolean,
  audioDevices: Array,
  videoDevices: Array,
  selectedAudioDevice: Object,
  selectedVideoDevice: Object,
  bitrate: [Number, String],
  canChangeBitrate: Boolean,
  republishing: Boolean,
  newMessage: Object,
  videoSimulcast: Boolean,
});

const emit = defineEmits([
  'toggle-mic',
  'toggle-camera',
  'toggle-screen',
  'toggle-participants-mute',
  'leave-room',
  'update:selected-audio-device',
  'update:selected-video-device',
  'update:bitrate',
  'sendRoomMessage',
  'toggle-video-simulcast',
  'broadcast'
]);

const roomId = ref('');
const canChangeDevices = true;
const buttonClasses = 'bg-blue-500 hover:bg-blue-700 text-white text-xs font-bold py-2 px-4 rounded';
const inputClasses = 'border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5';

const micText = computed(() => {
  return props.micEnabled ? 'Mic: on' : 'Mic: off';
})

const cameraText = computed(() => {
  return props.cameraEnabled ? 'Video: on' : 'Video: off';
})

const screenText = computed(() => {
  return props.screenEnabled ? 'End' : 'Sharing';
})

const muteAllText = computed(() => {
  return props.participantsMuted ? 'Enable sound' : 'Disable sound';
})

const toggleMic = () => {
  emit('toggle-mic');
};

const toggleCamera = () => {
  emit('toggle-camera');
};

const toggleScreen = () => {
  emit('toggle-screen');
};

const toggleParticipantsMute = () => {
  emit('toggle-participants-mute');
};

const toggleVideoSimulcast = () => {
  emit('toggle-video-simulcast');
};

const leaveRoom = () => {
  emit('leave-room');
};

const submitHandler = (e) => {
  emit('sendRoomMessage', e.target[0].value)
  e.target.reset();
}

const broadcast = () => {
  const roomIdToBroadcast = roomId.value.trim();
  if (roomIdToBroadcast) {
    emit('broadcast', roomIdToBroadcast)
  }
}
</script>

<style lang="scss">
.tools {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;

  &-element {
    flex-grow: 1;
    flex-shrink: 0;

    &.message {
      width: 100%;
      display: flex;
      gap: 30px;
      flex-wrap: nowrap;

      & form {
        display: flex;
        gap: 30px;
        flex: 3;
      }

      & input {
        background-color: white;
        flex: 2;
      }
    }

    &.buttons {
      display: flex;
      justify-content: center;
      gap: 10px;
    }
  }

  .multiselect__single {
    white-space: nowrap;
  }
}

@media (max-width: 1279px) {
  .tools {
    flex-wrap: wrap;

    &-element {
      flex-wrap: wrap;
    }
  }
}

@media (min-width: 1280px) {
  .tools {
    &-element {
      display: flex;
      align-items: center;

      &.buttons {
        justify-content: flex-end;

        &.without-devices {
          width: 100%;
          justify-content: center;
        }
      }
    }
  }
}
</style>