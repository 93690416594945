import {MediaStreamsKinds} from "@/api/Call";

export class Participant {
    #socketConnect;

    name = '';
    image = '';
    userId = '';
    videoEnabled = false;
    audioEnabled = false;
    isSpeaking = false;
    tracks = {};
    sid = '';
    isMutedVideo = false;
    isMutedAudio = false;

    constructor(participant, socket) {
        this.name = participant?.name || '';
        this.image = participant?.image || '';
        this.userId = participant?.userId || '';
        this.sid = participant?.sid || '';
        this.videoEnabled = participant?.videoEnabled || false;
        this.audioEnabled = participant?.audioEnabled || false;
        this.isSpeaking = participant?.isSpeaking || false;
        this.#socketConnect = socket;
    }

    subscribeTrack(MediaStreamKind) {};

    unsubscribeTrack(MediaStreamKind) {};

    attachTrack(MediaStreamKind) {};

    detachTrack(MediaStreamKind) {};

    disableAudio() {
        this.tracks[MediaStreamsKinds.Microphone].track.enabled = false;
        this.isMutedAudio = true;
    };

    enableAudio() {
        this.tracks[MediaStreamsKinds.Microphone].track.enabled = true;
        this.isMutedAudio = false;
    };

    disableVideo() {
        this.tracks[MediaStreamsKinds.Camera].track.enabled = false;
        this.isMutedVideo = true;
    };

    enableVideo() {
        this.tracks[MediaStreamsKinds.Camera].track.enabled = true;
        this.isMutedVideo = false;
    };

    addTrack(MediaStreamKind, Track) {
        this.tracks[MediaStreamKind] = Track;
    }

    removeTrack(MediaStreamKind) {
        delete this.tracks[MediaStreamKind];
    }

    getTrack(MediaStreamKind) {
        return this.tracks?.[MediaStreamKind]
    }

    setStreamQuality(quality) {
        if (this.tracks?.[MediaStreamsKinds.Camera]) {
            const trackId = this.tracks[MediaStreamsKinds.Camera].id;
            this.tracks[MediaStreamsKinds.Camera].track.currentVideoQuality =  quality;
            const signal = {
                trackSetting: {
                    trackSids: [trackId],
                    quality: quality,
                }
            };

            this.#socketConnect.send(JSON.stringify(signal));

            console.log('setQuality', signal);
        }
    }
}