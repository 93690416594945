import { createApp } from 'vue'
import App from './App.vue'
import './assets/main.css'
import 'vue-multiselect/dist/vue-multiselect.css'
import * as Sentry from "@sentry/vue";
import {CaptureConsole} from "@sentry/integrations";

const app = createApp(App)

if (location.hostname !== "localhost" && location.hostname !== "127.0.0.1") {
    Sentry.init({
        app,
        dsn: process.env.VUE_APP_SENTRY_DSN,
        tunnel: process.env.VUE_APP_SENTRY_TUNNEL,
        trackComponents: true,
        logErrors: true,
        tracesSampleRate: 1.0,
        integrations: [
            new CaptureConsole({
                levels: ['error']
            })
        ],
    });
}

app.mount('#app')
